<template>
  <div
    class="modal fade"
    tabindex="-10"
    id="modal_esporta_economato_mensile"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Esportazione report mensile</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="reset()"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>
        <div class="modal-body">
          <div class="card-body p-6">
            <div class="ps-5 pe-5">
              <div class="row">
                <div class="col-md-4">
                  <label class="fw-bold text-gray-800">Comitato*</label>
                  <SelectInput
                    :options="comitati"
                    name="id_comitato"
                    :value="id_comitato"
                    @changeSelect="setComitato($event)"
                  />
                </div>
                <div class="col-md-4">
                  <label class="fw-bold text-gray-800">Stagione*</label>
                  <SelectInput
                    :options="stagioni"
                    name="Stagione"
                    placeholder="-"
                    :value="stagione"
                    @changeSelect="setStagione($event)"
                  />
                </div>
                <div class="col-md-4">
                  <label class="fw-bold text-gray-800">Mese*</label>
                  <SelectInput
                    :options="mesi"
                    name="meseReport"
                    placeholder="-"
                    :value="mese"
                    @changeSelect="setMese($event)"
                  />
                </div>
              </div>
              <input
                class="form-check-input mt-5 mx-2"
                type="checkbox"
                id="flexCheckDefault"
                :value="dettaglio"
                :checked="dettaglio == true"
                @click="dettaglio = !dettaglio"
              />
              <label class="fw-bold mt-5 text-gray-800 pe-5"
                >Esporta dettaglio report</label
              >
              <!--  <input
                class="form-check-input mt-5 mx-2"
                type="checkbox"
                id="flexCheckDefault"
                :value="tutteLeStagioni"
                :checked="tutteLeStagioni == true"
                @click="tutteLeStagioni = !tutteLeStagioni"
              />
              <label class="fw-bold mt-5 text-gray-800 text-end"
                >Esporta tutte le stagioni</label
              > -->
              <div class="row mt-5">
                <div class="col-sm-12 text-gray-800">
                  <label class="fw-bold">Note esportazione:</label>
                  <textarea
                    type="text"
                    class="form-control"
                    placeholder=""
                    aria-label=""
                    v-model="note"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <span
            type="button"
            class="me-3 badge bg-light-danger rounded text-danger fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="modalEsporta"
            @click="reset()"
          >
            Chiudi
          </span>
          <span
            type="button"
            class="badge bg-secondary rounded blueFit fs-6"
            @click="esporta()"
          >
            Richiedi esportazione
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed, getCurrentInstance } from "vue";
import {
  esportaSintesiMensileEconomatoDettaglio,
  esportaSintesiMensileEconomato,
} from "@/requests/esportazioniRequests";

import { useRouter } from "vue-router";

import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { alertFailed } from "@/composables/swAlert";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

export default {
  name: "modal-esporta-albo",
  components: { Loading, SelectInput },
  props: {},
  setup() {
    const store = useStore();
    const router = useRouter();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const note = ref(null);
    const mese = ref(null);
    const stagioneSelected = computed(() => store.getters.stagioneSelected);
    const stagione = ref(stagioneSelected.value);
    const id_comitato = ref(null);
    const dettaglio = ref(false);
    /* const tutteLeStagioni = ref(false); */

    const setComitato = (event) => {
      id_comitato.value = event;
    };
    const setStagione = (event) => {
      stagione.value = event;
    };
    const setMese = (event) => {
      mese.value = event;
    };

    const isLoading = ref(false);

    const getComitati = () => {
      store.dispatch("setStoreListData", {
        apiLink: globalApi.LOOKUP_COMITATI,
        itemName: "comitati_list",
      });
    };
    getComitati();

    const esporta = () => {
      isLoading.value = true;
      if (dettaglio.value == true) {
        esportaSintesiMensileEconomatoDettaglio(
          id_comitato.value,
          stagione.value,
          mese.value,
          note.value
        ).then((res) => {
          isLoading.value = false;
          if (res.status == 200) {
            Swal.fire({
              html: "L'esportazione verrà effettuata dal sistema.<br><br> E' possibile visualizzare lo stato dell'esportazione ed effettuare il download del documento all'interno della pagina 'Esportazioni'",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Lista Esportazioni",
              cancelButtonText: "Chiudi",
              showCancelButton: true,
              customClass: {
                confirmButton: "btn fw-bold btn-light-success",
                cancelButton: "btn fw-bold btn-light-danger",
              },
            }).then((input) => {
              document.getElementById("modalEsporta").click();
              if (input.isConfirmed) {
                // window.location.href = `/esportazioni`;
                router.push(`/esportazioni`);
                return;
              }
            });
            note.value = null;
          } else {
            alertFailed(
              res.data.message
                ? res.data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
          }
        });
      } else {
        esportaSintesiMensileEconomato(
          id_comitato.value,
          stagione.value,
          mese.value,
          note.value
        ).then((res) => {
          isLoading.value = false;
          if (res.status == 200) {
            Swal.fire({
              html: "L'esportazione verrà effettuata dal sistema.<br><br> E' possibile visualizzare lo stato dell'esportazione ed effettuare il download del documento all'interno della pagina 'Esportazioni'",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Lista Esportazioni",
              cancelButtonText: "Chiudi",
              showCancelButton: true,
              customClass: {
                confirmButton: "btn fw-bold btn-light-success",
                cancelButton: "btn fw-bold btn-light-danger",
              },
            }).then((input) => {
              document.getElementById("modalEsporta").click();
              if (input.isConfirmed) {
                // window.location.href = `/esportazioni`;
                router.push(`/esportazioni`);
                return;
              }
            });
            note.value = null;
          } else {
            alertFailed(
              res.data.message
                ? res.data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
          }
        });
      }
    };

    const reset = () => {
      id_comitato.value = null;
      mese.value = null;
      stagione.value = stagioneSelected.value;
      dettaglio.value = false;
      note.value = null;
      //tutteLeStagioni.value = false;
    };

    const stagioni = computed(() => store.getters.stagioni);
    /*  stagioni.value.unshift({ id: 10000, label: "Tutte le stagioni" }); */

    return {
      setComitato,
      setStagione,
      setMese,
      id_comitato,
      stagione,
      mese,
      dettaglio,
      //tutteLeStagioni,
      note,
      esporta,
      comitati: computed(() =>
        store.getters.getStateFromName("resultscomitati_list")
      ),
      mesi: [
        { id: "1", label: "Gennaio" },
        { id: "2", label: "Febbraio" },
        { id: "3", label: "Marzo" },
        { id: "4", label: "Aprile" },
        { id: "5", label: "Maggio" },
        { id: "6", label: "Giugno" },
        { id: "7", label: "Luglio" },
        { id: "8", label: "Agosto" },
        { id: "9", label: "Settembre" },
        { id: "10", label: "Ottobre" },
        { id: "11", label: "Novembre" },
        { id: "12", label: "Dicembre" },
      ],
      stagioni,
      isLoading,
      reset,
    };
  },
};
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
    margin-top: 5rem;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
    margin-top: 4rem;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
