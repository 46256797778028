<template>
  <div class="mx-auto my-auto text-center" v-if="isLoading">
    <div class="spinner-border text-gray-600" role="status"></div>
    <span class="text-gray-600 ps-3 fs-2">Loading</span>
  </div>
  <div v-if="!isLoading && !error">
    <!--     <div class="row mt-5">
      <div class="col-6"></div>
      <div class="col-6">
        <div class="text-end mb-5" style="margin-right: 19px">
          Situazione al <b>27/06/2022 10:27</b>
        </div>
      </div>
    </div> -->
    <div class="row g-5 g-xl-8">
      <div class="col-xl-4">
        <StatisticsWidget01
          widget-classes="card-xl-stretch mb-5 mb-xl-8"
          background=""
          title="Tesseramenti"
          :numberOk="riepilogo.tess_approvate"
          descriptionOk="tesseramenti autorizzati in questa stagione"
          :number="riepilogo.tess_non_approvate"
          description="tesseramenti non approvati in questa stagione"
        ></StatisticsWidget01>
      </div>
      <div class="col-xl-4">
        <StatisticsWidget01
          widget-classes="card-xl-stretch mb-5 mb-xl-8"
          background=""
          title="Affiliazioni"
          :numberOk="riepilogo.aff_approvate"
          descriptionOk="affiliazioni autorizzate in questa stagione"
          :number="riepilogo.aff_non_approvate"
          description="affiliazioni non approvate in questa stagione"
        ></StatisticsWidget01>
      </div>
      <div class="col-xl-4">
        <StatisticsWidget01
          widget-classes="card-xl-stretch mb-5 mb-xl-8"
          background=""
          title="Pagamenti"
          :numberOk="riepilogo.pag_approvate"
          descriptionOk="pagamenti autorizzati in questa stagione"
          :number="riepilogo.pag_non_approvate"
          description="pagamenti non approvati in questa stagione"
        ></StatisticsWidget01>
      </div>
    </div>
    <div class="row g-5 g-xl-8">
      <div class="col-xl-6">
        <StatisticsWidget01
          widget-classes="card-xl-stretch mb-5 mb-xl-8"
          background=""
          title="Conguaglio tassa approvazione torneo"
          :numberOk="riepilogo.conguaglio_tassa_appr"
          descriptionOk="società che presentano una quota a conguaglio (causale 480) per non aver effettuato tornei FITP Tpra con almeno 16 giocatori nel primo semestre dell'anno"
        ></StatisticsWidget01>
      </div>
      <div v-if="isEnabled('fnGestioneEconomatoExportMav')" class="col-xl-6">
        <StatisticsWidget01
          widget-classes="card-xl-stretch mb-5 mb-xl-8"
          background=""
          title="MAV"
          :numberOk="riepilogo.pagamenti_mav_convertiti"
          descriptionOk="pagamenti con MAV convertiti in Bonifici"
          :numberOki="riepilogo.pagamenti_mav_convertiti_pagati"
          descriptionOki="pagamenti con MAV convertiti di cui è anche stato pagato il bollettino"
        ></StatisticsWidget01>
      </div>
    </div>
    <!-- <div class="row">
      <div class="text-center">
        <button
          type="button"
          class="btn btn-light text-gray-700 fs-5"
          @click="esportaDati()"
        >
          <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta dati
        </button>
      </div>
    </div> -->
    <div class="row">
      <div class="text-start">
        <button
          type="button"
          class="btn btn-light text-gray-700 fs-5"
          data-bs-toggle="modal"
          data-bs-target="#modal_esporta_economato_mensile"
        >
          <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta report
          mensile
        </button>
      </div>
    </div>
    <ModalEsportaSintesiMensile />
  </div>
  <div v-if="!isLoading && error">
    <div class="text-gray-700 pt-3 fs-2 text-center">
      Attenzione! Si è verificato un errore. Riprovare più tardi.
    </div>
    <div class="text-center mt-4">
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { defineComponent, onMounted, ref, computed, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import StatisticsWidget01 from "../../../components/widgets/statsistics/Widget01.vue";
import isEnabled from "@/composables/isEnabled.js";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import { viewRiepilogo } from "@/requests/economatoRequests";
import ModalEsportaSintesiMensile from "@/components/components-fit/gestione/economato/ModalEsportaSintesiMensile.vue";

export default defineComponent({
  name: "sintesi-economato",
  components: { StatisticsWidget01, ModalEsportaSintesiMensile },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Sintesi Economato");
    });
    const store = useStore();

    const stagione = computed(() => store.getters.stagioneSelected);

    const isLoading = ref(false);
    const error = ref(false);

    const riepilogo = ref({});

    const viewSintesi = (stagione) => {
      isLoading.value = true;
      viewRiepilogo(stagione).then((res) => {
        isLoading.value = false;
        if (res.status == 200) {
          riepilogo.value = res.data.results[0];
        } else {
          error.value = true;
        }
      });
    };
    viewSintesi(stagione.value);

    watch(stagione, () => {
      viewSintesi(stagione.value);
    });

    const esportaDati = () => {
      Swal.fire({
        html: "Funzionalità presto disponibile",
        icon: "warning",
        buttonsStyling: false,
        confirmButtonText: "Chiudi",
        customClass: {
          confirmButton: "btn fw-bold btn-light-warning",
        },
      });
    };

    return {
      stagione,
      riepilogo,
      error,
      isLoading,
      isEnabled,
      esportaDati,
    };
  },
});
</script>
