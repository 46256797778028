import services from "../axios/dbManag";

export const viewRiepilogo = (stagione) => {
  return services.dbManag
    .post("/economato/report/sintesi/list", {
      stagione,
      id_Comitato: 0,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
