import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-body shadow" }
const _hoisted_2 = { class: "card-title fw-bolder text-gray-700 fs-6" }
const _hoisted_3 = { class: "row mt-3" }
const _hoisted_4 = { class: "col-9" }
const _hoisted_5 = { class: "text-gray-600 fw-bold fs-7 m-0" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "col-3 text-end" }
const _hoisted_8 = {
  class: "fw-bolder text-success fs-5",
  style: {"white-space":"nowrap"}
}
const _hoisted_9 = { class: "row mt-3" }
const _hoisted_10 = { class: "col-9" }
const _hoisted_11 = { class: "text-gray-600 fw-bold fs-7 m-0" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "col-3 text-end" }
const _hoisted_14 = {
  class: "fw-bolder text-success fs-5",
  style: {"white-space":"nowrap"}
}
const _hoisted_15 = { class: "row mt-3" }
const _hoisted_16 = { class: "col-9" }
const _hoisted_17 = { class: "text-gray-600 fw-bold fs-7 m-0" }
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = { class: "col-3 text-end" }
const _hoisted_20 = {
  class: "fw-bolder text-danger fs-5",
  style: {"white-space":"nowrap"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card bgi-no-repeat"]),
    style: _normalizeStyle([`background-image: url('/media/svg/shapes/${_ctx.background}')`, {"background-position":"right top","background-size":"30% auto"}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, [
            _createElementVNode("span", { innerHTML: _ctx.descriptionOk }, null, 8, _hoisted_6)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.numberOk), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("p", _hoisted_11, [
            _createElementVNode("span", { innerHTML: _ctx.descriptionOki }, null, 8, _hoisted_12)
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.numberOki), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("p", _hoisted_17, [
            _createElementVNode("span", { innerHTML: _ctx.description }, null, 8, _hoisted_18)
          ])
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.number), 1)
        ])
      ])
    ])
  ], 6))
}