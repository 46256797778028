
import { defineComponent } from "vue";

export default defineComponent({
  name: "kt-widget-1",
  props: {
    widgetClasses: String,
    background: String,
    title: String,
    numberOk: Number,
    descriptionOk: String,
    numberOki: Number,
    descriptionOki: String,
    number: Number,
    description: String,
  },
  components: {},
});
